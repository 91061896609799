var FormBuilder = FormBuilder || {};

FormBuilder.Base = (function ($) {
    'use strict';

    function init() {

    }

    return {
        init: init
    };

})(jQuery);

$(function () {
    FormBuilder.Base.init();
});